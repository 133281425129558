<template>
  <div id="footer-el">
    <div
      v-if="blockLicense"
      class="text-center bg-license py-2"
      :class="[footerColorText, blockLicense]"
    >
      {{ $t("other.license") }}
    </div>
    <div class="footer" :class="footerColorBg">
      <b-container class="py-2">
        <b-row align-v="center">
          <b-col class="col mr-auto order-sm-first">
            <img
              src="../assets/img/footer_logo.svg"
              alt="Logo"
              class="img-fluid logo-footer"
            />
          </b-col>
          <b-col cols="auto" class="order-first">
            <b-navbar-nav class="text-uppercase footer-navbar d-inline-block">
              <b-nav-item :to="routerPath + '/tours'">{{
                $t("nav_menu.tours")
              }}</b-nav-item>
            </b-navbar-nav>
          </b-col>
          <b-col cols="auto" class="order-last">
            <b-navbar-nav class="text-uppercase footer-navbar d-inline-block">
              <b-nav-item :to="routerPath + '/rules'">{{
                $t("nav_menu.rules")
              }}</b-nav-item>
            </b-navbar-nav>
          </b-col>
        </b-row>
      </b-container>
    </div>
  </div>
</template>

<script type="text/javascript">
export default {
  name: "Footer",
  data() {
    return {
      routerPath: this.routerPath,
      routerName: this.routerName,
      footerColorBg: this.footerColorBg,
      folderImages: this.folderImages,
      footerColorText: this.footerColorText,
      blockLicense: this.blockLicense
    };
  },
  watch: {
    $route() {
      this.routerPath = this.$route.matched[0].path;
      this.routerName = this.$route.matched[0].path.slice(1);
      this.footerColorBg = "footer-bg-" + this.routerName;
      this.footerColorText = "text-" + this.routerName;
      let rTN = this.$route.name;

      if (
        [
          "efootball_tour",
          "ehockey_tour",
          "ebasketball_tour"
          //"rocket_league_tour",
          //"counter_strike_tour"
        ].indexOf(rTN) !== -1
      ) {
        this.blockLicense = true;
      } else {
        this.blockLicense = false;
      }
    }
  }
};
</script>

<style scoped>
.footer {
  -webkit-box-shadow: 0 -1px 4px 0 rgba(0, 0, 0, 0.67);
  -moz-box-shadow: 0 -1px 4px 0 rgba(0, 0, 0, 0.67);
  box-shadow: 0 -1px 4px 0 rgba(0, 0, 0, 0.67);
}
.footer-bg-ehockey {
  background: linear-gradient(90.06deg, #59c3ff 0%, #42b0ff 99.96%);
}
.footer-bg-ebasketball {
  background: linear-gradient(90.06deg, #03fbfb 0%, #00f0f0 99.96%);
}
.footer-bg-efootball {
  background: linear-gradient(90.06deg, #8fff37 0%, #61dc00 99.96%);
}
.footer-bg-rocket-league {
  background: linear-gradient(90deg, #ff3fe0 0%, #ff1edb 100%);
}
.footer-bg-counter-strike {
  background: linear-gradient(90.06deg, #ffb739 0%, #ffa200 99.96%);
}
.footer-bg-mir-tankov {
  background: linear-gradient(90deg, #016817 0%, #016817 100%);
}
.text-ebasketball {
  color: #03fbe9 !important;
}
.text-efootball {
  color: #70fb03 !important;
}
.text-ehockey {
  color: #46ceff !important;
}
.text-rocket-league {
  color: #ff3fe0 !important;
}
.text-counter-strike {
  color: #ffb739 !important;
}
.text-mir-tankov {
  color: #016817 !important;
}
.bg-license {
  background: rgba(31, 35, 34, 1);
  line-height: 22px;
}
.footer-navbar li {
  padding: 3px 0;
}
.footer-navbar li > a {
  color: #000000;
  padding: 0 5px;
}

.footer-navbar li > a:hover {
  color: #000000;
  text-decoration: underline;
}
.logo-footer {
  height: 59px;
}
</style>
